import React from 'react';
import { Link } from 'react-router-dom';
import { useMediaQuery } from '@react-hook/media-query';

// Import animations
import { motion } from 'framer-motion';
import {
  fadeInOut,
  containerVariants,
  child,
  titleVariants,
  cardsContainerVariants,
  cardItemVariants,
  scrollContainer,
  scrollChild,
} from '../utils/animations';

// Import icons, styles, images
import heroImg from '../assets/images/hero.png';
import howImg from '../assets/images/how.png';
import howImgMobile from '../assets/images/how_mobile.png';
import testimonial from '../assets/images/slide_00.png';
import Form from '../components/Form';
// import check from '../assets/images/check.png';

const Landing = ({ data, images, blogData, blogImages, openModal }) => {
  const hero = data.heroSection;
  const banner = data.bannerSection;
  const info = data.infoSection;
  const how = data.howItWorksSection;
  const price = data.pricingSection;

  // Device viewport
  const mobile = useMediaQuery('only screen and (max-width: 767px)');
  const mobileSm = useMediaQuery('only screen and (max-width: 430px)');

  return (
    <motion.div
      variants={fadeInOut}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="content-wrapper"
    >
      {/* <AnimatePresence key="landing" mode="wait"> */}
      {/* Hero section */}
      <motion.div
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
        className="hero position-relative bg-transparent"
      >
        <div className="container text-center text-md-start">
          <motion.div variants={child} className="col-12 col-md-6">
            <h1>{hero.h1}</h1>
            <p className="text-grey mt-3 mb-6">{hero.description}</p>
            <div className="my-5">
              <a href="#how-it-works">
                <button className="btn btn-violet">{hero.cta1}</button>
              </a>
              <button
                className={`${mobileSm ? 'btn btn-outlined mt-3' : 'btn btn-outlined ms-4'}`}
                onClick={() => openModal('popup')}
              >
                {hero.cta2}
              </button>
            </div>
          </motion.div>
          {mobile ? (
            <motion.div variants={child} className="col-12">
              <img className="img-fluid" src={heroImg} alt="hero" />
            </motion.div>
          ) : (
            <motion.div variants={child} className="image-wrapper position-absolute p-0">
              <img className="img-fluid" src={heroImg} alt="hero" />
            </motion.div>
          )}
        </div>
      </motion.div>

      {/* Banner section */}
      <motion.div
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
        className="banner position-relative bg-gradient-violet"
      >
        <div className="container text-center my-4">
          <motion.h1
            variants={child}
            className="text-yellow"
            dangerouslySetInnerHTML={{ __html: banner.h1 }}
          />
          <motion.p variants={child} className="text-white mt-5 mb-0">
            {banner.description}
          </motion.p>
        </div>
        {mobile ? null : (
          <svg
            className="banner-image p-4"
            width="1627"
            height="365"
            viewBox="0 0 1627 365"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1529.5 274.798C1529.5 274.798 1383.99 381.126 1205 354.798C1026.01 328.47 625.516 -1.26798 339 5.79773C135.063 10.827 70.1086 118.066 70.1086 118.066"
              stroke="#A8A9AC"
              strokeOpacity="0.45"
              strokeWidth="2.5"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeDasharray="12 12"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M1591.81 192.337L1585.08 194.517L1587.26 201.24C1588.47 204.96 1586.43 208.947 1582.72 210.147C1579 211.353 1575.01 209.318 1573.81 205.602L1571.63 198.876L1564.9 201.056C1561.18 202.262 1557.2 200.228 1555.99 196.508C1554.79 192.798 1556.82 188.811 1560.54 187.601L1567.26 185.424L1565.08 178.698C1563.88 174.981 1565.92 170.994 1569.63 169.791C1573.35 168.585 1577.33 170.62 1578.54 174.34L1580.72 181.066L1587.44 178.885C1591.16 177.676 1595.15 179.711 1596.35 183.43C1597.56 187.143 1595.53 191.131 1591.81 192.337ZM1544.73 251.577C1544.73 251.577 1535.74 191.415 1545.39 172.519C1554.25 155.154 1575.59 148.236 1592.95 157.098C1610.32 165.961 1617.23 187.298 1608.37 204.664C1598.73 223.559 1544.73 251.577 1544.73 251.577ZM1599.38 144.501C1575.03 132.074 1545.22 141.74 1532.79 166.09C1520.36 190.441 1535.09 270.473 1535.09 270.473C1535.09 270.473 1608.54 235.443 1620.97 211.092C1633.4 186.742 1623.73 156.928 1599.38 144.501Z"
              fill="#A8A9AC"
              fillOpacity="0.45"
            />
            <g clipPath="url(#clip0_1267_27)">
              <path
                d="M53.7 126.324L30.7641 136.184C30.3492 136.395 30.0141 136.731 29.8008 137.148L19.2938 158.331C18.8625 159.174 19.0195 160.201 19.6828 160.878C20.107 161.309 20.6813 161.539 21.2602 161.539C21.5859 161.539 21.9141 161.469 22.2211 161.319L44.6133 152.019C45.0234 151.822 45.3633 151.501 45.5812 151.102L56.632 129.357C57.1031 128.506 56.9625 127.452 56.2922 126.753C55.6172 126.055 54.5648 125.884 53.7023 126.322L53.7 126.324ZM26.2102 154.491L32.5828 141.477L39.0188 149.532L26.2102 154.491ZM42.825 146.785L36.3211 138.645L49.2703 133.536L42.825 146.785ZM37.5 105.798C16.7906 105.798 0 122.586 0 143.298C0 164.009 16.7883 180.798 37.5 180.798C58.2117 180.798 75 164.009 75 143.298C75 122.586 58.2117 105.798 37.5 105.798ZM37.5 176.185C19.4063 176.185 4.6875 161.391 4.6875 143.298C4.6875 125.204 19.4063 110.485 37.5 110.485C55.5937 110.485 70.3125 125.204 70.3125 143.298C70.3125 161.391 55.5937 176.185 37.5 176.185Z"
                fill="#A8A9AC"
                fillOpacity="0.45"
              />
            </g>
            <defs>
              <clipPath id="clip0_1267_27">
                <rect width="75" height="75" fill="white" transform="translate(0 105.798)" />
              </clipPath>
            </defs>
          </svg>
        )}
      </motion.div>

      {/* Info section */}
      <div className="info position-relative bg-white">
        <div className="container py-7 pt-md-14 pb-mb-14 text-center">
          <motion.div variants={titleVariants} initial="hidden" whileInView="show" className="lead">
            <p className="label">{info.label}</p>
            <h3 className="my-3 my-md-6">{info.h1}</h3>
          </motion.div>
          <motion.div
            variants={cardsContainerVariants}
            initial="hidden"
            whileInView="show"
            className="infocard-wrapper"
          >
            {info.cards.map((card) => (
              <motion.div variants={cardItemVariants} key={card.id} className="card">
                <img className="card-img-top" src={images[card.image]} alt={card.title} />
                <div className="card-body">
                  <h5 className="text-violet text-center">{card.title}</h5>
                  <p>{card.description}</p>
                </div>
              </motion.div>
            ))}
          </motion.div>
          <div className="mt-3 mt-md-6">
            <button onClick={() => openModal('popup')} className="btn btn-violet">
              {info.cta}
            </button>
          </div>
        </div>
      </div>

      {/* How it works section */}
      <div id="how-it-works" className="how-it-works position-relative bg-gradient-violet-50">
        <motion.div
          variants={scrollContainer}
          initial="hidden"
          whileInView="show"
          className="container py-7 pt-md-14 pb-mb-14 text-center"
        >
          <h1>{how.h1}</h1>
          <h3 className="mx-2 mx-md-0">{how.description}</h3>
          <motion.img
            variants={scrollChild}
            className="img-fluid my-6"
            src={mobile ? howImgMobile : howImg}
            alt="how-it-works"
          />
          <button onClick={() => openModal('modal')} className="btn btn-violet my-3 my-md-8">
            {how.cta0}
          </button>
          <h2 className="my-4">{how.h2}</h2>
          <motion.div variants={scrollChild} className="row g-0 testimonials">
            <div className="col-12 col-md-6">
              <img className="mb-3 md-md-0" src={testimonial} alt="testimonials" />
            </div>
            <div className="col-12 col-md-6 text-center text-md-start my-auto ps-2">
              <p className="">
                "TripBuddy has been a game-changer for our website. It's simple, fun, and our
                visitors love the personalized recommendations. Truly a must-have for any tourist
                destination!"
              </p>
              <p className="text-small">Elizabeta Smukavc, Tourism Kranjska Gora</p>
            </div>
          </motion.div>
        </motion.div>
      </div>

      {/* Pricing section */}
      <div id="pricing" className="pricing position-relative bg-gradient-violet">
        <div className="container py-7 pt-md-14 pb-mb-14 text-center">
          <h1 className="text-white">{price.h1}</h1>
          <h3 className="text-white mb-4 mb-md-3">{price.description}</h3>
          <div className="mb-10 mt-4 row g-0 d-flex justify-content-center">
            <div className="my-2 my-md-6 col-12 col-md-6 d-flex justify-content-center align-items-center">
              <div className="info bg-grey-light">{price.info1}</div>
              <div className="symbols">+</div>
              <div className="info bg-grey-light">{price.info2}</div>
            </div>
            <div className="my-2 my-md-6 col-12 col-md-6 d-flex justify-content-center align-items-center">
              <div className="symbols">=</div>
              <div className="info result bg-mint-light">{price.info3}</div>
            </div>
          </div>
          <motion.div variants={scrollContainer} initial="hidden" whileInView="show">
            {' '}
            <motion.h2 variants={scrollChild} className="my-3">
              Get a personalised offer for your Tourist Destination.{' '}
            </motion.h2>
            <motion.div variants={scrollChild} className="form-wrap">
              <Form popup="false" />
            </motion.div>
          </motion.div>

          {/* <div className="price-cardwrap">
            <div className="card text-start">
              <p className="price-label">{price.pricePlan[0].name}</p>
              <div>
                <h1 className="gradient-pink">{price.pricePlan[0].price}</h1>
                <table className="product-info mt-3">
                  <tbody className="text-grey">
                    <tr>
                      <td className="td-check">
                        <img src={check} alt="check" />
                      </td>
                      <td>{price.pricePlan[0].property1}</td>
                    </tr>
                    <tr>
                      <td className="td-check">
                        <img src={check} alt="check" />
                      </td>
                      <td>{price.pricePlan[0].property2}</td>
                    </tr>
                    <tr>
                      <td className="td-check">
                        <img src={check} alt="check" />
                      </td>
                      <td>{price.pricePlan[0].property3}</td>
                    </tr>
                  </tbody>
                </table>
                <button
                  onClick={() => (modalOpen ? close() : open())}
                  className="btn btn-price bg-gradient-pink mt-5"
                >
                  {price.cta}
                </button>
              </div>
            </div>

            <div className="card text-start">
              <p className="price-label">{price.pricePlan[1].name}</p>
              <div>
                <h1 className="gradient-mint">{price.pricePlan[1].price}</h1>
                <table className="product-info mt-3">
                  <tbody className="text-grey">
                    <tr>
                      <td className="td-check">
                        <img src={check} alt="check" />
                      </td>
                      <td>{price.pricePlan[1].property1}</td>
                    </tr>
                    <tr>
                      <td className="td-check">
                        <img src={check} alt="check" />
                      </td>
                      <td>{price.pricePlan[1].property2}</td>
                    </tr>
                    <tr>
                      <td className="td-check">
                        <img src={check} alt="check" />
                      </td>
                      <td>{price.pricePlan[1].property3}</td>
                    </tr>
                  </tbody>
                </table>
                <button
                  onClick={() => (modalOpen ? close() : open())}
                  className="btn btn-price bg-gradient-mint mt-5"
                >
                  {price.cta}
                </button>
              </div>
            </div>

            <div className="card text-start">
              <p className="price-label">{price.pricePlan[2].name}</p>
              <div>
                <h1 className="gradient-yellow">{price.pricePlan[2].price}</h1>
                <table className="product-info mt-3">
                  <tbody className="text-grey">
                    <tr>
                      <td className="td-check">
                        <img src={check} alt="check" />
                      </td>
                      <td>{price.pricePlan[2].property1}</td>
                    </tr>
                    <tr>
                      <td className="td-check">
                        <img src={check} alt="check" />
                      </td>
                      <td>{price.pricePlan[2].property2}</td>
                    </tr>
                    <tr>
                      <td className="td-check">
                        <img src={check} alt="check" />
                      </td>
                      <td>{price.pricePlan[2].property3}</td>
                    </tr>
                  </tbody>
                </table>
                <button
                  onClick={() => (modalOpen ? close() : open())}
                  className="btn btn-price bg-gradient-yellow mt-5"
                >
                  {price.cta}
                </button>
              </div>
            </div>
          </div> */}
        </div>
      </div>

      {/* Blog section */}
      <div className="blog-section position-relative bg-transparent">
        <motion.div
          variants={scrollContainer}
          initial="hidden"
          whileInView="show"
          className="container py-7 pt-md-14 pb-mb-14 text-center"
        >
          <h1>{data.blogSection.h1}</h1>

          <motion.div variants={scrollChild} className="posts-wrapper">
            {blogData.slice(-3).map((blog) => (
              <Link
                reloadDocument
                relative="path"
                className="link"
                key={blog.id}
                to={`/blog/${blog.id}-${blog.link}`}
              >
                <div className="card">
                  <img
                    className="card-img-top"
                    src={blogImages[blog.coverImage]}
                    alt={blog.keyword}
                  />
                  <div className="card-body text-start">
                    <div>
                      <h5 className="">{blog.metaTitle}</h5>
                      <p>{blog.metaDescription}</p>
                    </div>
                    <p className="link-blog">Read blog post</p>
                  </div>
                </div>
              </Link>
            ))}
          </motion.div>

          <div className="mt-4 mt-md-6">
            <a href="/blog">
              <button className="btn btn-violet">{data.blogSection.cta}</button>
            </a>
          </div>
        </motion.div>
      </div>
      {/* </AnimatePresence> */}
    </motion.div>
  );
};

export default Landing;
