import React from 'react';

import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';

// Import animations
import { motion } from 'framer-motion';
import { fadeInOut, containerVariants, child } from '../utils/animations';

// Import helper functions
import { truncateString } from '../utils/helpers';

const Post = ({ blogData, blogImages }) => {
  const { blogId } = useParams();

  // Use the blogId directly as the id
  const selectedBlog = blogData.find((blog) => blog.id === parseInt(blogId));

  return (
    <motion.div
      variants={fadeInOut}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="post position-relative bg-transparent"
    >
      <motion.div
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
        className="container py-7 pt-md-12 pb-md-17"
      >
        {selectedBlog && (
          <div className="post-wrapper">
            <motion.div variants={child} className="image-container mb-10">
              <img
                className="card-img-top"
                src={blogImages[selectedBlog.coverImage]}
                alt={selectedBlog.keyword}
              />
              <div className="overlay">
                <h1 className="text-white text-center">{selectedBlog.metaTitle}</h1>
              </div>
            </motion.div>

            <motion.div variants={child} className="blog-content px-5">
              <div dangerouslySetInnerHTML={{ __html: selectedBlog.section1 }}></div>
              <img
                className="img-fluid"
                src={blogImages[selectedBlog.image1]}
                alt={selectedBlog.keyword}
              />
            </motion.div>
          </div>
        )}
        <motion.div variants={child} className="read-also mt-10">
          <h1 className="text-center">Read also</h1>
          <div className="posts-wrapper">
            {blogData.slice(-3).map((blog) => {
              let blogDescription = blog.metaDescription;

              if (blog.metaTitle.length > 67) {
                blogDescription = truncateString(blog.metaDescription, 130);
              }

              return (
                <Link
                  reloadDocument
                  relative="path"
                  className="link"
                  key={blog.id}
                  to={`/blog/${blog.id}-${blog.link}`}
                >
                  <div className="card">
                    <img
                      className="card-img-top"
                      src={blogImages[blog.coverImage]}
                      alt={blog.keyword}
                    />
                    <div className="card-body text-start">
                      <div>
                        <h5>{blog.metaTitle}</h5>
                        <p>{blogDescription}</p>
                      </div>

                      <p className="link-blog">Read blog post</p>
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default Post;
