import React from 'react';

import logo from '../assets/images/logo.svg';

// Import icons, images
import { FaLinkedinIn as Linkedin } from 'react-icons/fa';
import { MdEmail as Email } from 'react-icons/md';

// FaInstagram as Instagram,  FaFacebookF as Facebook, FaYoutube as Youtube,

const Footer = ({ handleLinkClick, openModal }) => {
  return (
    <footer className="footer bg-dark mt-0">
      <div className="container py-13 py-md-15">
        <div className="row gy-6 gy-lg-0">
          <div className="col-md-4">
            <div className="widget">
              <img className="mb-4" src={logo} alt="logo" />
              <p className="mb-2">
                Trip Buddy by{' '}
                <a target="_blank" rel="noopener noreferrer" href="https://escapebox.si/en/">
                  Escapebox
                </a>
              </p>
              <div className="small">
                <a href="https://policies.google.com/privacy">Privacy Policy</a> and{' '}
                <a href="https://policies.google.com/terms">Terms and Conditions.</a>.
              </div>
              <nav className="nav social social-white">
                {/* <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/Escapebox.si"
                >
                  <Facebook />
                </a> */}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.linkedin.com/company/escapebox-si/"
                >
                  <Linkedin />
                </a>
                <a target="_blank" rel="noopener noreferrer" href="mailto:info@escapebox.si">
                  <Email />
                </a>
                {/* <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.instagram.com/escapebox.si/"
                >
                  <Instagram />
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.youtube.com/channel/UCq4CgmJVbrEEM8nuF-ofw1Q/featured"
                >
                  <Youtube />
                </a> */}
              </nav>
            </div>
          </div>
          <div className="col-md-4">
            <div className="widget">
              <h4 className="widget-title text-white mb-3">About Trip Buddy 💡</h4>
              <ul className="list-unstyled mb-0">
                <li className="nav-link" onClick={() => handleLinkClick('#how-it-works')}>
                  <span>How it works</span>
                </li>
                <li>
                  <span className="nav-link" onClick={() => handleLinkClick('#pricing')}>
                    Pricing
                  </span>
                </li>
                <li>
                  <a href="/blog">Blog</a>
                </li>

                <li>
                  <span className="nav-link" onClick={() => openModal('modal')}>
                    Try it out
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-4">
            <div className="widget">
              <h4 className="widget-title text-white mb-3">Articles 🎯</h4>
              <ul className="list-unstyled mb-0">
                <li>
                  <a href="/blog/7-personalized-content-in-tourism">
                    How to create quality Personalized Content as a Tourist Destination
                  </a>
                </li>
                <li>
                  <a href="/blog/4-tourism-marketing">
                    6 Proven Tourism Marketing Strategies to Boost Engagement
                  </a>
                </li>
                <li>
                  <a href="/blog/6-AR-and-VR-in-travel">
                    Tourism Trends to Staying Ahead with Your Destination
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
