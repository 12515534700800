// Import React stuff
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Axios instance
import API from '../config/axios';

// Import animations
import { motion } from 'framer-motion';
import { fadeInOut, containerVariants, child } from '../utils/animations';

const Login = ({ setToken }) => {
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Admin login
  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });

  const [errors, setErrors] = useState({
    username: '',
    password: '',
  });

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await API.post('/admin', formData);

      if (response.status === 200) {
        navigate('/users');
        setToken(response.data.token);
      } else {
        // Handle login failure (e.g., show an error message)
        setErrors({
          username: 'Incorrect username!',
          password: 'Incorrect password!',
        });
      }
    } catch (error) {
      console.error('Login error:', error);
    }
  };

  return (
    <motion.div
      variants={fadeInOut}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="login"
    >
      {/* <AnimatePresence key="blog" mode="wait"> */}
      <motion.div variants={containerVariants} initial="hidden" animate="visible" exit="exit">
        {' '}
        <motion.h1 variants={child} className="my-6">
          Admin Login{' '}
        </motion.h1>
        <motion.div variants={child}>
          <div className="form-group">
            <input
              type="text"
              name="username"
              className={`form-control ${errors.username ? 'is-invalid' : ''}`}
              value={formData.username}
              placeholder="Username*"
              onChange={handleChange}
              required
            />
            <div className="error">{errors.username}</div>
          </div>
          <div className="form-group">
            <input
              type="password"
              name="password"
              className={`form-control ${errors.password ? 'is-invalid' : ''}`}
              value={formData.password}
              placeholder="Password*"
              onChange={handleChange}
              required
            />
            <div className="mt-3">
              {errors.password ? (
                <p style={{ color: '#DC3545' }} className="warning-text">
                  {errors.password}
                </p>
              ) : null}
            </div>
          </div>
        </motion.div>
        <motion.div variants={child} className="mt-7">
          <button onClick={handleLogin} className="btn btn-violet">
            Login
          </button>
        </motion.div>
      </motion.div>
      {/* </AnimatePresence> */}
    </motion.div>
  );
};

export default Login;
