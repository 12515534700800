import { motion, AnimatePresence } from 'framer-motion';
import Backdrop from './Backdrop';
import { useMediaQuery } from '@react-hook/media-query';

// Import animations
import { containerVariants, child } from '../utils/animations';

const dropIn = {
  hidden: {
    y: '-100vh',
    opacity: 0,
  },
  visible: {
    y: '0',
    opacity: 1,
    transition: {
      duration: 0.5,
      type: 'spring',
      damping: 30,
      stiffness: 300,
    },
  },
  open: {
    y: '100vh',
    opacity: 0,
  },
};

const Modal = ({
  modalName,
  handleClose,
  isOpen,
  size,
  modalBackground,
  children,
  modalBodyStyle,
  close,
}) => {
  const mobile = useMediaQuery('only screen and (max-width: 767px)');

  const getModalSize = () => {
    if (mobile) {
      return {
        width: '100%',
        height: '100%',
      };
    }
    switch (size) {
      case 'small':
        return {
          width: '300px',
          height: '200px',
        };
      case 'medium':
        return {
          width: '925px',
          height: '535px',
        };
      case 'large':
        return {
          width: '1100px',
          height: '700px',
        };
      default:
        return {
          width: '500px',
          height: '300px',
        };
    }
  };

  const closeBtn = () => {
    switch (close) {
      case 'in':
        return {
          top: '12px',
          right: '12px',
        };
      case 'out':
        return {
          top: '20px',
          right: '20px',
          backgroundColor: 'white',
          padding: '15px',
          borderRadius: '10px',
        };

      default:
        return {
          top: '12px',
          right: '12px',
        };
    }
  };

  const modalSize = getModalSize();
  const btnPosition = closeBtn();

  return (
    <Backdrop onClick={handleClose}>
      <motion.div
        onClick={(e) => e.stopPropagation()}
        className={`modal ${isOpen ? 'open' : ''}`}
        variants={dropIn}
        initial="hidden"
        animate={isOpen ? 'visible' : 'hidden'}
        exit="exit"
        key={modalName}
      >
        <AnimatePresence initial={false} key={modalName} mode="wait">
          <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            className={`modal-content ${modalBackground}`}
            style={modalSize}
          >
            <motion.div
              variants={child}
              className="close-btn"
              onClick={handleClose}
              style={btnPosition}
            >
              <svg
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.9693 15.59C20.5877 16.2084 20.897 16.9382 20.897 17.7792C20.897 18.6203 20.5878 19.3501 19.9693 19.9685C19.3509 20.5869 18.6088 20.9085 17.743 20.9332C16.9019 20.9332 16.1722 20.624 15.5537 20.0056L10.8784 15.3303L6.2402 19.9685C5.62177 20.5869 4.89202 20.8961 4.05096 20.8961C3.23463 20.9209 2.5049 20.6117 1.86174 19.9685C1.24331 19.3501 0.934088 18.6203 0.934088 17.7792C0.934088 16.9382 1.2433 16.2084 1.86173 15.59L6.49995 10.9518L1.82463 6.27646C1.2062 5.65804 0.896973 4.92828 0.896973 4.08722C0.92171 3.22142 1.24329 2.4793 1.86172 1.86087C2.48015 1.24244 3.2099 0.933227 4.05096 0.933228C4.89203 0.933228 5.62178 1.24245 6.24021 1.86088L10.9155 6.5362L15.5909 1.86088C16.2093 1.24245 16.939 0.933236 17.7801 0.933236C18.6212 0.933237 19.3509 1.24246 19.9693 1.86089C20.5878 2.47932 20.897 3.20904 20.897 4.0501C20.897 4.89117 20.5878 5.62092 19.9693 6.23935L15.294 10.9147L19.9693 15.59Z"
                  fill="#282871"
                />
              </svg>
            </motion.div>
            <motion.div variants={child} className="modal-body" style={modalBodyStyle}>
              {children}
            </motion.div>
          </motion.div>
        </AnimatePresence>
      </motion.div>
    </Backdrop>
  );
};

export default Modal;
