import axios from 'axios';

const isDevelopment = process.env.NODE_ENV === 'development';

const API_ENDPOINT = isDevelopment ? 'http://localhost:3001' : 'https://tripbuddy.escapebox.si/';

const API = axios.create({
  baseURL: `${API_ENDPOINT}/`,
  headers: {
    'Content-type': 'application/json',
  },
});

export default API;
