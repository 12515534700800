// Import React stuff
import { useState } from 'react';

// Import helper functions
import { validateForm, isValidEmail } from '../utils/helpers';

// Import axios
import API from '../config/axios';

const Form = ({ popup, handleClose }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Clear the corresponding error when the user starts typing or checks the checkbox
    if (name === 'name' && errors.name) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        name: false,
      }));
    }

    if (name === 'email' && errors.email) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: false,
      }));

      // Check if the email is valid
      const isEmailValid = isValidEmail(value);
      setUnvalidEmail(!isEmailValid);
    }
  };

  // Admin login
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    destination: '',
  });

  const [errors, setErrors] = useState({
    name: '',
    email: '',
  });

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  // Validate email
  const [unvalidEmail, setUnvalidEmail] = useState(false);

  const handleUser = async (e) => {
    e.preventDefault();

    // Form validation
    const formErrors = validateForm(formData);

    if (formErrors.name || formErrors.email) {
      // There are validation errors, set them and return
      setErrors(formErrors);
      return;
    }

    // Validate email
    const isEmailValid = isValidEmail(formData.email);

    if (!isEmailValid) {
      setUnvalidEmail(true);
      return;
    }

    try {
      const response = await API.post('/add-user', formData);

      if (response.status === 201) {
        // Show success message
        setShowSuccessMessage(true);
        setFormData({
          name: '',
          email: '',
          destination: '',
        });

        setTimeout(() => {
          setShowSuccessMessage(false);
          if (popup === 'true') {
            handleClose();
          }
        }, 10000);
      } else {
        // Handle login failure (e.g., show an error message)
      }
    } catch (error) {
      console.error('User error:', error);
    }
  };

  return (
    <div className={`form ${popup === 'true' ? 'text-center' : ' text-centertext-md-start'}`}>
      {showSuccessMessage ? (
        <>
          <h2 className="mb-5">Thank you!</h2>
          <p className={` ${popup === 'true' ? '' : 'text-mint'}`}>
            We'll get back to you in no time.
          </p>
        </>
      ) : (
        <>
          {popup === 'true' ? <h1 className="mb-3 pt-3">Get a personalised offer</h1> : null}
          <p className={`${popup === 'true' ? '' : 'text-white'}`}>
            Contact us and get a <b>personalised offer</b> for your Tourist destination.
          </p>
          <div className="mt-6">
            <div className="form-group">
              <input
                type="text"
                name="name"
                className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                value={formData.name}
                placeholder="Name*"
                onChange={handleChange}
                required
              />
              <div className="mt-2">
                {errors.name ? (
                  <p style={{ color: '#DC3545', fontSize: '14px' }} className="warning-text">
                    Please insert your name.
                  </p>
                ) : null}
              </div>
            </div>
            <div className="form-group">
              <input
                type="email"
                name="email"
                className={`form-control ${errors.email || unvalidEmail ? 'is-invalid' : ''}`}
                value={formData.email}
                placeholder="Work email*"
                onChange={(e) => {
                  handleChange(e);
                  const isEmailValid = isValidEmail(e.target.value);
                  setUnvalidEmail(!isEmailValid); // Reset unvalidEmail based on email validity
                }}
                required
              />

              <div className="mt-2">
                {errors.email ? (
                  <p style={{ color: '#DC3545', fontSize: '14px' }} className="warning-text">
                    Please insert your email.
                  </p>
                ) : unvalidEmail ? (
                  <p style={{ color: '#DC3545', fontSize: '14px' }} className="warning-text">
                    Unvalid email.
                  </p>
                ) : null}
              </div>
            </div>
            <div className="form-group">
              <input
                type="text"
                name="destination"
                className={`form-control `}
                value={formData.destination}
                placeholder="Your destination webiste"
                onChange={handleChange}
              />
            </div>

            <button
              onClick={handleUser}
              className={`btn mt-5 ${popup === 'true' ? 'btn-violet' : 'btn-pink'}`}
            >
              Submit
            </button>
            {/* <p className={`text-sm mt-5 ${popup === 'true' ? '' : 'text-white'}`}>
              <b>🎁 GIFT:</b> Get a <b>FREE book</b> on Trends in Toruism for 2023 directly in your
              email!
            </p> */}
          </div>
        </>
      )}
    </div>
  );
};

export default Form;
