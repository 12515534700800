import React from 'react';
import { motion } from 'framer-motion';

// Variants
const menuItem = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

const menuContainer = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

const sidebar = {
  open: (height = 1000) => ({
    clipPath: `circle(${height * 2 + 200}px at 40px 40px)`,
    transition: {
      type: 'spring',
      stiffness: 20,
      restDelta: 2,
    },
  }),
  closed: {
    clipPath: 'circle(0.01px at 40px 40px)',
    fill: 'transparent',
    transition: {
      delay: 0.5,
      type: 'spring',
      stiffness: 400,
      damping: 40,
    },
  },
};

const Sidebar = ({ handleLinkClick, toggleOpen }) => {
  return (
    <motion.div className="sidebar" variants={sidebar}>
      <motion.ul variants={menuContainer}>
        <motion.li variants={menuItem} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}>
          <span
            onClick={() => {
              toggleOpen(false);
              handleLinkClick('#how-it-works');
            }}
          >
            How it works
          </span>
        </motion.li>
        <motion.li variants={menuItem} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}>
          <span
            onClick={() => {
              toggleOpen(false);
              handleLinkClick('#pricing');
            }}
          >
            Pricing
          </span>
        </motion.li>
        <motion.li variants={menuItem} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}>
          <a href="/blog">
            <span>Blog</span>
          </a>
        </motion.li>
        <motion.li variants={menuItem} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://calendly.com/markomaucec/20?month=2023-08"
          >
            <button className="btn btn-sidebar my-5">Book a call</button>
          </a>
        </motion.li>
      </motion.ul>
    </motion.div>
  );
};

export default Sidebar;
