import Lottie from 'lottie-react';

const Loader = () => {
  return (
    <div className="loader-wrapper">
      <Lottie
        style={{ height: '300px', width: '300px' }}
        animationData={{
          v: '4.10.0',
          fr: 23.9759979248047,
          ip: 0,
          op: 22.9999980092804,
          w: 500,
          h: 500,
          nm: 'loader-7',
          ddd: 0,
          assets: [],
          layers: [
            {
              ddd: 0,
              ind: 1,
              ty: 4,
              nm: 'Shape Layer 4',
              sr: 1,
              ks: {
                o: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0.667], y: [1] },
                      o: { x: [0.333], y: [0] },
                      n: ['0p667_1_0p333_0'],
                      t: 0,
                      s: [30],
                      e: [100],
                    },
                    {
                      i: { x: [0.667], y: [1] },
                      o: { x: [0.333], y: [0] },
                      n: ['0p667_1_0p333_0'],
                      t: 6,
                      s: [100],
                      e: [30],
                    },
                    { t: 9.99999913446976 },
                  ],
                  ix: 11,
                },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [250, 250, 0], ix: 2 },
                a: { a: 0, k: [-21.609, -39.609, 0], ix: 1 },
                s: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                      o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                      n: ['0p667_1_0p333_0', '0p667_1_0p333_0', '0p667_1_0p333_0'],
                      t: 0,
                      s: [100, 100, 100],
                      e: [120, 120, 100],
                    },
                    {
                      i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                      o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                      n: ['0p667_1_0p333_0', '0p667_1_0p333_0', '0p667_1_0p333_0'],
                      t: 6,
                      s: [120, 120, 100],
                      e: [100, 100, 100],
                    },
                    { t: 9.99999913446976 },
                  ],
                  ix: 6,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: 'gr',
                  it: [
                    {
                      d: 1,
                      ty: 'el',
                      s: { a: 0, k: [50, 50], ix: 2 },
                      p: { a: 0, k: [0, 0], ix: 3 },
                      nm: 'Ellipse Path 1',
                      mn: 'ADBE Vector Shape - Ellipse',
                      hd: false,
                    },
                    {
                      ty: 'st',
                      c: { a: 0, k: [0.18431372549, 0.756862745098, 0.745098039216, 1], ix: 3 },
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 4, ix: 5 },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      nm: 'Stroke 1',
                      mn: 'ADBE Vector Graphic - Stroke',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [-21.609, -39.609], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Ellipse 1',
                  np: 3,
                  cix: 2,
                  ix: 1,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
              ],
              ip: 0,
              op: 133.999988401895,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 2,
              ty: 4,
              nm: 'Shape Layer 3',
              sr: 1,
              ks: {
                o: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0.667], y: [1] },
                      o: { x: [0.167], y: [0] },
                      n: ['0p667_1_0p167_0'],
                      t: 0,
                      s: [30],
                      e: [30],
                    },
                    {
                      i: { x: [0.667], y: [1] },
                      o: { x: [0.333], y: [0] },
                      n: ['0p667_1_0p333_0'],
                      t: 4,
                      s: [30],
                      e: [100],
                    },
                    {
                      i: { x: [0.667], y: [1] },
                      o: { x: [0.333], y: [0] },
                      n: ['0p667_1_0p333_0'],
                      t: 10,
                      s: [100],
                      e: [30],
                    },
                    { t: 13.9999987882577 },
                  ],
                  ix: 11,
                },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [250, 250, 0], ix: 2 },
                a: { a: 0, k: [-21.609, -39.609, 0], ix: 1 },
                s: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                      o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                      n: ['0p667_1_0p333_0', '0p667_1_0p333_0', '0p667_1_0p333_0'],
                      t: 4,
                      s: [100, 100, 100],
                      e: [120, 120, 100],
                    },
                    {
                      i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                      o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                      n: ['0p667_1_0p333_0', '0p667_1_0p333_0', '0p667_1_0p333_0'],
                      t: 10,
                      s: [120, 120, 100],
                      e: [100, 100, 100],
                    },
                    { t: 13.9999987882577 },
                  ],
                  ix: 6,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: 'gr',
                  it: [
                    {
                      d: 1,
                      ty: 'el',
                      s: { a: 0, k: [100, 100], ix: 2 },
                      p: { a: 0, k: [0, 0], ix: 3 },
                      nm: 'Ellipse Path 1',
                      mn: 'ADBE Vector Shape - Ellipse',
                      hd: false,
                    },
                    {
                      ty: 'st',
                      c: { a: 0, k: [0.43529399797, 0.862744978362, 0.854902020623, 1], ix: 3 },
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 4, ix: 5 },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      nm: 'Stroke 1',
                      mn: 'ADBE Vector Graphic - Stroke',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [-21.609, -39.609], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Ellipse 1',
                  np: 3,
                  cix: 2,
                  ix: 1,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
              ],
              ip: 0,
              op: 133.999988401895,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 3,
              ty: 4,
              nm: 'Shape Layer 2',
              sr: 1,
              ks: {
                o: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0.667], y: [1] },
                      o: { x: [0.333], y: [0] },
                      n: ['0p667_1_0p333_0'],
                      t: 0,
                      s: [30],
                      e: [30],
                    },
                    {
                      i: { x: [0.667], y: [1] },
                      o: { x: [0.333], y: [0] },
                      n: ['0p667_1_0p333_0'],
                      t: 8,
                      s: [30],
                      e: [100],
                    },
                    {
                      i: { x: [0.667], y: [1] },
                      o: { x: [0.333], y: [0] },
                      n: ['0p667_1_0p333_0'],
                      t: 14,
                      s: [100],
                      e: [30],
                    },
                    { t: 17.9999984420456 },
                  ],
                  ix: 11,
                },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [250, 250, 0], ix: 2 },
                a: { a: 0, k: [-21.609, -39.609, 0], ix: 1 },
                s: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                      o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                      n: ['0p667_1_0p333_0', '0p667_1_0p333_0', '0p667_1_0p333_0'],
                      t: 8,
                      s: [100, 100, 100],
                      e: [120, 120, 100],
                    },
                    {
                      i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                      o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                      n: ['0p667_1_0p333_0', '0p667_1_0p333_0', '0p667_1_0p333_0'],
                      t: 14,
                      s: [120, 120, 100],
                      e: [100, 100, 100],
                    },
                    { t: 17.9999984420456 },
                  ],
                  ix: 6,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: 'gr',
                  it: [
                    {
                      d: 1,
                      ty: 'el',
                      s: { a: 0, k: [150, 150], ix: 2 },
                      p: { a: 0, k: [0, 0], ix: 3 },
                      nm: 'Ellipse Path 1',
                      mn: 'ADBE Vector Shape - Ellipse',
                      hd: false,
                    },
                    {
                      ty: 'st',
                      c: { a: 0, k: [0.007843137255, 0.756862745098, 0.745098039216, 1], ix: 3 },
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 4, ix: 5 },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      nm: 'Stroke 1',
                      mn: 'ADBE Vector Graphic - Stroke',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [-21.609, -39.609], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Ellipse 1',
                  np: 3,
                  cix: 2,
                  ix: 1,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
              ],
              ip: 0,
              op: 133.999988401895,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 4,
              ty: 4,
              nm: 'Shape Layer 1',
              sr: 1,
              ks: {
                o: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0.667], y: [1] },
                      o: { x: [0.333], y: [0] },
                      n: ['0p667_1_0p333_0'],
                      t: 0,
                      s: [30],
                      e: [30],
                    },
                    {
                      i: { x: [0.667], y: [1] },
                      o: { x: [0.333], y: [0] },
                      n: ['0p667_1_0p333_0'],
                      t: 12,
                      s: [30],
                      e: [100],
                    },
                    {
                      i: { x: [0.667], y: [1] },
                      o: { x: [0.333], y: [0] },
                      n: ['0p667_1_0p333_0'],
                      t: 18,
                      s: [100],
                      e: [30],
                    },
                    { t: 21.9999980958335 },
                  ],
                  ix: 11,
                },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [250, 250, 0], ix: 2 },
                a: { a: 0, k: [-21.609, -39.609, 0], ix: 1 },
                s: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                      o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                      n: ['0p667_1_0p333_0', '0p667_1_0p333_0', '0p667_1_0p333_0'],
                      t: 12,
                      s: [100, 100, 100],
                      e: [120, 120, 100],
                    },
                    {
                      i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                      o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                      n: ['0p667_1_0p333_0', '0p667_1_0p333_0', '0p667_1_0p333_0'],
                      t: 18,
                      s: [120, 120, 100],
                      e: [100, 100, 100],
                    },
                    { t: 21.9999980958335 },
                  ],
                  ix: 6,
                },
              },
              ao: 0,
              shapes: [
                {
                  ty: 'gr',
                  it: [
                    {
                      d: 1,
                      ty: 'el',
                      s: { a: 0, k: [200, 200], ix: 2 },
                      p: { a: 0, k: [0, 0], ix: 3 },
                      nm: 'Ellipse Path 1',
                      mn: 'ADBE Vector Shape - Ellipse',
                      hd: false,
                    },
                    {
                      ty: 'st',
                      c: { a: 0, k: [0.43529399797, 0.862744978362, 0.854902020623, 1], ix: 3 },
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 4, ix: 5 },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      nm: 'Stroke 1',
                      mn: 'ADBE Vector Graphic - Stroke',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [-21.609, -39.609], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Ellipse 1',
                  np: 3,
                  cix: 2,
                  ix: 1,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
              ],
              ip: 0,
              op: 133.999988401895,
              st: 0,
              bm: 0,
            },
          ],
        }}
      />
    </div>
  );
};

export default Loader;
