export const downloadFile = ({ data, fileName, fileType }) => {
  const blob = new Blob([data], { type: fileType });

  const a = document.createElement('a');
  a.download = fileName;
  a.href = window.URL.createObjectURL(blob);
  const clickEvt = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  a.dispatchEvent(clickEvt);
  a.remove();
};

export function importAll(r) {
  let images = {};
  r.keys().map((item) => {
    return (images[item.replace('./', '')] = r(item));
  });
  return images;
}

// Email validation
export const isValidEmail = (email) => {
  const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/u;
  return emailRegex.test(email);
};

// Form validation
export const validateForm = (form) => {
  const formErrors = {};

  if (form.name.trim() === '') {
    formErrors.name = true;
  }

  if (form.email.trim() === '') {
    formErrors.email = true;
  }

  return formErrors;
};

// Format date
export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${day}.${month}.${year}`;
};

// Truncate string
export const truncateString = (string, maxLength) => {
  if (string.length <= maxLength) {
    return string;
  }
  return string.slice(0, maxLength) + '...';
};
