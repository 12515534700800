// Import React stuff
import React, { useState, useEffect } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { Navigate } from 'react-router-dom';

// Import views
import Landing from './views/Landing';
import Login from './views/Login';
import Users from './views/Users';
import Blog from './views/Blog';
import Post from './views/Post';

// Import components
import Header from './components/Header';
import Footer from './components/Footer';
import Modal from './components/Modal';
import Form from './components/Form';
import Loader from './components/Loader';

// Import animations
import { AnimatePresence, motion } from 'framer-motion';

// Import styles
import './assets/styles/style.scss';

// Import data
import pageData from './data/landing-page.json';
import blogData from './data/blog-content.json';

// Import helper functions
import { importAll } from './utils/helpers';

const App = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Loader
  const [isLoading, setIsLoading] = useState(false);

  const [token, setToken] = useState();

  const [modalOpen, setModalOpen] = useState({
    modal: false,
    popup: false,
    calendly: false,
  });

  const openModal = (modalName) => {
    setModalOpen({ ...modalOpen, [modalName]: true });
  };

  const closeModal = (modalName) => {
    setModalOpen({ ...modalOpen, [modalName]: false });
  };

  const images = importAll(require.context('./assets/images', false, /\.(png|jpe?g|svg|gif)$/));
  const blogImages = importAll(
    require.context('./assets/images/blog', false, /\.(png|jpe?g|svg|gif)$/)
  );

  const handleLinkClick = (sectionId, e) => {
    // Check if the current URL is the home page
    if (location.pathname === '/') {
      // Find the target element by the specified sectionId
      const targetElement = document.querySelector(sectionId);

      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      navigate('/');

      setTimeout(() => {
        // Wait for the redirect to complete, then scroll
        const targetElement = document.querySelector(sectionId);

        if (targetElement) {
          targetElement.scrollIntoView({ behavior: 'smooth' });
        }
      }, 500);
    }
  };

  useEffect(() => {
    // Scroll to (0,0) after the path is changed.
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [location]);

  const handleBooking = () => {
    openModal('calendly');
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  };

  return (
    <>
      <Header handleLinkClick={handleLinkClick} handleBooking={handleBooking} />
      <div className="bg-gradient-violet-50 position-relative overflow-hidden">
        <motion.div
          initial={{ opacity: 0.5 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0.5 }}
          transition={{ duration: 0.3 }}
        >
          <AnimatePresence key="tripbuddy" mode="wait">
            <Routes location={location} key={location.pathname}>
              <Route
                path="/"
                element={
                  <Landing
                    data={pageData}
                    images={images}
                    blogImages={blogImages}
                    blogData={blogData}
                    openModal={openModal}
                  />
                }
              />
              <Route path="/login" element={<Login setToken={setToken} />} />
              <Route
                path="/blog/:blogId"
                element={<Post blogImages={blogImages} blogData={blogData} />}
              />
              <Route path="/blog" element={<Blog blogImages={blogImages} blogData={blogData} />} />
              <Route
                path="/users"
                element={token ? <Users setToken={setToken} /> : <Navigate to="/" />}
              />
            </Routes>
          </AnimatePresence>
        </motion.div>
      </div>
      <Footer handleLinkClick={handleLinkClick} openModal={openModal} />

      {modalOpen.popup && (
        <Modal
          modalName="popup"
          isOpen={modalOpen.popup}
          handleClose={() => closeModal('popup')}
          size="medium"
          close="in"
          modalBackground="image"
          modalBodyStyle={{
            padding: '10px 30px',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <div className="col-12 col-md-6 ps-0 ps-md-12">
            <Form popup="true" handleClose={() => closeModal('popup')} />
          </div>
        </Modal>
      )}

      {modalOpen.modal && (
        <Modal
          modalName="modal"
          isOpen={modalOpen.modal}
          handleClose={() => closeModal('modal')}
          size="large"
          close="out"
          modalBackground="transparent"
          modalBodyStyle={{
            padding: '0',
            margin: '0',
          }}
        >
          <iframe
            title="Embedded Content"
            src="https://kam-pa-kam.escapebox.si/?lang=en"
            width="100%"
            height="100%"
            allowFullScreen
          ></iframe>
        </Modal>
      )}

      {modalOpen.calendly && (
        <Modal
          modalName="calendly"
          isOpen={modalOpen.calendly}
          handleClose={() => closeModal('calendly')}
          size="large"
          close="out"
          modalBackground="white"
          modalBodyStyle={{
            padding: '0',
            margin: '0',
          }}
        >
          {isLoading ? (
            <Loader />
          ) : (
            <iframe
              id="calendly"
              title="Embedded Content"
              src="https://calendly.com/markomaucec/20?back=1&month=2023-09"
              width="100%"
              height="100%"
              allowFullScreen
            ></iframe>
          )}
        </Modal>
      )}
    </>
  );
};

export default App;
