// Import React stuff
import React, { useState } from 'react';
import { useMediaQuery } from '@react-hook/media-query';

// Axios instance
import API from '../config/axios';

// Import helper functions
import { downloadFile, formatDate } from '../utils/helpers';

// Import styles, icons
import { FaRedo as Refresh } from 'react-icons/fa';

const Users = ({ setToken }) => {
  const [usersData, setUsersData] = useState([]);

  const mobile = useMediaQuery('only screen and (max-width: 767px)');

  const fetchDataFromServer = async () => {
    try {
      const response = await API.get('/users');
      setUsersData(response.data.users);
      console.log(usersData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // Function to generate a filename with the current date
  const generateFilename = () => {
    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}-${
      currentDate.getMonth() + 1
    }-${currentDate.getDate()}`;
    console.log(`trip-buddy_data_${formattedDate}`);
    return `trip-buddy_data_${formattedDate}`;
  };

  const exportToJson = (e) => {
    e.preventDefault();
    downloadFile({
      data: JSON.stringify(usersData),
      fileName: `${generateFilename()}.json`,
      fileType: 'text/json',
    });
  };

  const exportToCsv = (e) => {
    e.preventDefault();
    let headers = ['Id,Name,Email,Destination,Date'];

    let usersCsv = usersData.reduce((acc, user) => {
      const { id, name, email, destination, createdAt } = user;
      const formattedDate = formatDate(createdAt);
      acc.push([id, name, email, destination, formattedDate].join(','));
      return acc;
    }, []);

    downloadFile({
      data: [...headers, ...usersCsv].join('\n'),
      fileName: `${generateFilename()}.csv`,
      fileType: 'text/csv',
    });
  };

  const Logout = () => {
    setToken(null);
  };

  const handleRefresh = () => {
    fetchDataFromServer(); // Fetch the latest data on refresh
  };

  return (
    <div className="user">
      <div className="px-0 px-md-5 d-flex justify-content-between">
        <button className="btn btn-outlined" onClick={Logout}>
          LOGOUT
        </button>
        <button className="btn btn-violet" onClick={handleRefresh}>
          <Refresh />
        </button>
      </div>
      <div className="px-0 py-3 px-md-5 py-md-5">
        <table className="table table-hover">
          <thead>
            <tr>
              {mobile ? null : <th scope="col">#</th>}
              {mobile ? null : <th scope="col">Name</th>}
              <th scope="col">Email</th>
              <th scope="col">{mobile ? 'Destination' : 'Destination Website'}</th>
              {mobile ? null : <th scope="col">Date</th>}
            </tr>
          </thead>
          <tbody>
            {usersData.map((user) => {
              const { id, name, email, destination, createdAt } = user;
              const formattedDate = formatDate(createdAt);

              return (
                <tr key={id}>
                  {mobile ? null : <td>{id}</td>}
                  {mobile ? null : <td>{name}</td>}
                  <td>{email}</td>
                  <td>{destination}</td>
                  {mobile ? null : <td>{formattedDate}</td>}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="d-flex justify-content-between justify-content-md-center my-3">
        <button className="btn btn-mint mx-md-3" onClick={exportToJson}>
          {mobile ? 'JSON' : 'JSON Download'}
        </button>
        <button className="btn btn-pink mx-md-3" onClick={exportToCsv}>
          {mobile ? 'CSV' : 'CSV Download'}
        </button>
      </div>
    </div>
  );
};

export default Users;
