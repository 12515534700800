// Slideshow

export const slideShow = {
  enter: (direction) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
};

export const slideVariants = {
  hiddenRight: {
    x: '100%',
    opacity: 0,
  },
  hiddenLeft: {
    x: '-100%',
    opacity: 0,
  },
  visible: {
    x: '0',
    opacity: 1,
    transition: {
      duration: 1,
    },
  },
  exit: {
    opacity: 0,
    scale: 0.8,
    transition: {
      duration: 0.5,
    },
  },
};

export const fadeInOut = {
  hidden: {
    opacity: 0.7,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 1,
    },
  },
  exit: {
    opacity: 0,
  },
};

export const containerVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.35,
      ease: 'easeInOut',
    },
  },
  exit: {
    opacity: 0,
  },
};

export const child = {
  hidden: { translateY: '-35px', opacity: 0 },
  visible: {
    translateY: 0,
    opacity: 1,
    transition: { duration: 1.15, type: 'spring' },
  },
};

// ON SCROLL
export const titleVariants = {
  hidden: {
    opacity: 0,
  },
  show: {
    opacity: 1,
    transition: {
      duration: 0.4,
      delay: 0.2,
    },
  },
};

export const cardsContainerVariants = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      delayChildren: 0.7,
      staggerChildren: 0.3,
    },
  },
};

export const cardItemVariants = {
  hidden: { opacity: 0, scale: 0.7 },
  show: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 1,
      type: 'spring',
      damping: 10,
      stiffness: 120,
    },
  },
};

export const scrollContainer = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      delayChildren: 0.7,
      staggerChildren: 0.5,
    },
  },
};

export const scrollChild = {
  hidden: { translateY: '-35px', opacity: 0 },
  show: {
    translateY: 0,
    opacity: 1,
    transition: { duration: 1.15, type: 'spring' },
  },
};
