import React from 'react';
import { Link } from 'react-router-dom';

// Import animations
import { motion } from 'framer-motion';
import { fadeInOut, containerVariants, child } from '../utils/animations';

const Blog = ({ blogImages, blogData, handleBlogClick }) => {
  return (
    <motion.div
      variants={fadeInOut}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="blog position-relative bg-transparent"
    >
      {/* <AnimatePresence key="blog" mode="wait"> */}
      <motion.div
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
        className="pt-md-10 pb-md-14"
      >
        <motion.h1 variants={child} className="text-center mb-6">
          Trip Buddy blog
        </motion.h1>{' '}
        <motion.div variants={child} className="posts-wrapper">
          <div className="row g-0">
            {' '}
            {blogData.map((blog) => (
              <div className="col-12 col-md-6 col-xl-4">
                <Link
                  reloadDocument
                  relative="path"
                  onClick={handleBlogClick}
                  className="link"
                  key={blog.id}
                  to={`/blog/${blog.id}-${blog.link}`}
                >
                  <div className="card">
                    {' '}
                    <img
                      className="card-img-top"
                      src={blogImages[blog.coverImage]}
                      alt={blog.keyword}
                    />
                    <div className="card-body text-start">
                      <h5 className="">{blog.metaTitle}</h5>
                      <p>{blog.metaDescription}</p>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </motion.div>
      </motion.div>
      {/* </AnimatePresence> */}
    </motion.div>
  );
};

export default Blog;
