import React from 'react';
import { motion, useCycle } from 'framer-motion';

import logo from '../assets/images/logo.svg';

import Sidebar from '../components/Sidebar';

const Path = (props) => (
  <motion.path
    fill="transparent"
    strokeWidth="2.5"
    stroke="#282871"
    strokeLinecap="round"
    {...props}
  />
);

const Header = ({ handleLinkClick, handleBooking }) => {
  const [isOpen, toggleOpen] = useCycle(false, true);

  return (
    <header className="header py-3 py-md-9">
      <motion.nav initial={false} animate={isOpen ? 'open' : 'closed'} className="navbar">
        <div className="container d-flex flex-row flex-nowrap w-100 justify-content-between align-items-center">
          <div className="navbar-brand w-100">
            <a href="https://tripbuddy.escapebox.si/">
              <img className="brand-logo" src={logo} alt="escapebox" />
            </a>
          </div>

          <button onClick={() => toggleOpen()} className="toggle-button">
            <svg width="23" height="23" viewBox="0 0 23 23">
              <Path
                className={`${isOpen ? 'toggle-button-path-opened' : ''}`}
                variants={{
                  closed: { d: 'M 2 2.5 L 20 2.5' },
                  open: { d: 'M 3 16.5 L 17 2.5' },
                }}
              />
              <Path
                d="M 2 9.423 L 20 9.423"
                className={`${isOpen ? 'toggle-button-path-opened' : ''}`}
                variants={{
                  closed: { opacity: 1 },
                  open: { opacity: 0 },
                }}
                transition={{ duration: 0.1 }}
              />
              <Path
                className={`${isOpen ? 'toggle-button-path-opened' : ''}`}
                variants={{
                  closed: { d: 'M 2 16.346 L 20 16.346' },
                  open: { d: 'M 3 2.5 L 17 16.346' },
                }}
              />
            </svg>
          </button>

          <Sidebar handleLinkClick={handleLinkClick} toggleOpen={toggleOpen} />

          <div className="navbar-items bg-transparent">
            <ul className="d-flex align-items-center">
              <li className="nav-item active">
                <span className="nav-link" onClick={() => handleLinkClick('#how-it-works')}>
                  How it works
                </span>
              </li>
              <li className="nav-item">
                <span className="nav-link" onClick={() => handleLinkClick('#pricing')}>
                  Pricing
                </span>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/blog">
                  Blog
                </a>
              </li>
              <li className="nav-item ps-6">
                <button className="btn btn-navbar" onClick={handleBooking}>
                  Book a call
                </button>
              </li>
            </ul>
          </div>
        </div>
      </motion.nav>
    </header>
  );
};

export default Header;
